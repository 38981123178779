import React, { useEffect, useCallback, useState } from 'react';

import L from 'leaflet';
import { renderToString } from 'react-dom/server'
import moment from 'moment';


import { useMap } from 'react-leaflet';

import 'leaflet-rotatedmarker';



const VehicleIcon = ({ assetData }) => {


    const map = useMap();
    const [theIcon, setTheIcon] = useState(null);
    const [theLabel, setTheLabel] = useState(null);


    const vehicleDevice = assetData && assetData.vehicleDevice;
    const coreData = assetData && assetData.coreData;



    let lat = 23.5251;
    let lng = 77.8081;
    let heading = 180;

    if (coreData) {
        lat = coreData.lat;
        lng = coreData.lng;
        heading = coreData.heading;
    }

    const markerOnClick = useCallback(
        () => {
            console.log('Marker clicked');
        },
        [],
    )



    const createMarker = useCallback(
        () => {
            if (map && vehicleDevice) {
                const tic = L.marker([lat, lng], {
                    rotationAngle: heading,
                    icon: L.icon({
                        iconUrl: `cargo_van_purple.png`,
                        //iconSize: [12, 24],
                        iconAnchor: [8, 15],
                    })
                }).on('click', markerOnClick);
                map.addLayer(tic);
                setTheIcon(tic);
            }
        },
        [lat, lng, heading, vehicleDevice, map, markerOnClick],
    )

    const createLabel = useCallback(
        () => {
            if (map && vehicleDevice) {
                const { regn } = vehicleDevice;
                const html = renderToString(
                    <div style={{ width: '140px', border: '1px', borderRadius: '3px', backgroundColor: 'white' }}>
                        <span style={{ color: 'firebrick', fontSize: 14 }}>&nbsp;<b>{regn}</b>&nbsp;</span>
                        <br />
                        <span style={{ color: 'blue', fontSize: 12 }}>&nbsp;{moment(coreData.dt_tracker).format("D MMM YYYY hh:mm a")}</span>
                        <br />
                        <span style={{ color: 'blue', fontSize: 12 }}>&nbsp;Speed: {coreData.speed} km/h&nbsp;</span>
                    </div>
                );

                const tlbl = L.marker([lat, lng], {
                    icon: L.divIcon({
                        html: html,
                        iconAnchor: [-7, -18],
                        className: 'dummy'
                    })
                }).on('click', markerOnClick);
                map.addLayer(tlbl);
                setTheLabel(tlbl);
            }
        },
        [lat, lng, vehicleDevice, map, coreData, markerOnClick],
    )

    useEffect(() => {
        if (!theIcon) {
            createMarker();
            createLabel();
        } else {
            theIcon.setRotationAngle(heading);
            theIcon.setLatLng([lat, lng]);
        }
        return () => {
            if (theIcon) {
                map.removeLayer(theIcon);
                setTheIcon(null);
            }
            if (theLabel) {
                map.removeLayer(theLabel);
                setTheLabel(null);
            }
        }
    }, [theIcon, theLabel, lat, lng, heading, map, createMarker, createLabel])



    return null;


}

export default React.memo(VehicleIcon);

