import React from 'react';
import { Polyline } from 'react-leaflet';




const OsmTailPath = ({ tail }) => {

    if (tail) {
        let tailPath = [];
        tail.forEach(tailPoint => {
            if (!isNaN(parseFloat(tailPoint.lat)) && !isNaN(parseFloat(tailPoint.lng))) {
                tailPath.push([parseFloat(tailPoint.lat), parseFloat(tailPoint.lng)]);

            }

        });
        tailPath = tailPath.reverse();

        return (
            <Polyline
                positions={tailPath}
                pathOptions={{ color: '#00008B' }}
            />
        );
    } else {
        return null;
    }
}

export default OsmTailPath;