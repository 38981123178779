import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import MapCenter from './MapCenter';
import OsmVehicleIcon from './OsmVehicleIcon';
import OsmTailPath from './OsmTailPath';
import 'leaflet-rotatedmarker';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';

const rnx01 = "https://mobileapi.roadmatics.com:10004/get_share_data?shareid="
const rnx02 = "https://mobileapi02.roadmatics.com:10004/get_share_data?shareid="

const App = () => {

  const [shareid, setShareid] = useState(null);

  const [data, setData] = useState({});

  const [sensors, setSensors] = useState({});
  const [tail, setTail] = useState([]);
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState([19, 73]);
  const [boxRows, setBoxRows] = useState(1);
  useEffect(() => {
    const id = window.location.pathname.split('/')[1];
    setShareid(id);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      //console.log("Loading data for shareid", shareid);
      //const link = `https://mobileapi.roadmatics.com:10004/get_share_data?shareid=${shareid}`;
      const link = `${rnx02}${shareid}`;
      const { data: response } = await axios.get(link);
      //console.log(response);
      setData(response);
      if (response && response.coreData) {
        if (response.coreData.lat && response.coreData.lng) {
          setPosition([response.coreData.lat, response.coreData.lng]);
          setSensors(response.sensors);
          setTail(response.tail);
          setAddress(response.address);
          setBoxRows(Object.keys(response.sensors.reefer_status).length + 1);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [shareid]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(timeoutId)
    };
  }, [shareid, fetchData]);

  useEffect(() => {
    const firstLoadtimeOut = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => {
      clearTimeout(firstLoadtimeOut);
    }
  }, [fetchData])




  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };
  const { height, width } = getWindowDimensions();
  const dimentions = {
    position: 'fixed',
    width: `${width}px`,
    height: `${height + 48}px`,
    left: `0px`,
    top: `0px`,
    overflowX: 'hidden',
    overflowY: 'hidden',
  };

  const boxPosition = {
    position: 'fixed',
    width: `${width}px`,
    height: `${15 * boxRows + 10}px`,
    left: `0px`,
    bottom: `0px`,
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000,
    textAlign: 'center',
    padding: '10px',
    borderTop: '1px solid black',
  }

  return (

    <MapContainer
      center={position}
      zoom={14}
      scrollWheelZoom={true}
      id="mapid"
      style={{ ...dimentions }}
    >
      <TileLayer
        attribution='Roadmatics <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://osm.roadr.in/osm_tiles/{z}/{x}/{y}.png"
      />

      <MapCenter position={position} />
      <OsmVehicleIcon assetData={data} />
      <OsmTailPath tail={tail} />
      <div style={boxPosition}>
      {sensors && sensors.reefer_status &&

          <div>
          {
            Object.keys(sensors.reefer_status).map((key, index) => {
              return (
                <div key={index}>
                  <span style={{ color: 'white' }}><b>{key}</b> : {sensors.reefer_status[key]}</span>
                </div>
              )
            })
          }

          </div>
        }
        <div><span style={{ color: 'lightgray' }}><em>{address.Location}</em></span></div>
      </div>
    </MapContainer>
  );

}

export default App;

/*
{position && <Marker position={position} icon={legalIcon}>
        <Popup>
          {moment(coreData.dt_tracker).format("D MMM YYYY hh:mm a")} <br /> Speed: {coreData.speed} km/h
        </Popup>
      </Marker>}
*/