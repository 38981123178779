import React from 'react';
import { useMap } from 'react-leaflet';

const MapCenter = ({ position }) => {
    const map = useMap();

    if (position) {
        map.setView(position);
    }

    return null;
};

export default MapCenter;